import { Api } from '@payler/payment-page-api-gate';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useNetworkErrorContext } from './network-error-context';
import { useTranslation } from 'react-i18next';

export type TPaymentPageEnv = {
  baseURL: string;
  doRedirect: boolean;
};

const LANG_LIST = ['ru', 'en'];

export type TPaymentPageConfigContext = TPaymentPageEnv & {
  api: Api;
  setLanguage: (lang: string) => void;
  language: string;
};

const PaymentPageContext = createContext<TPaymentPageConfigContext>(
  {} as TPaymentPageConfigContext,
);

export const PaymentPageConfigContextProvider: FCC<{
  env: TPaymentPageEnv;
}> = ({ children, env }) => {
  const { setError, error } = useNetworkErrorContext();
  const {
    i18n: { changeLanguage, language },
  } = useTranslation();
  useEffect(() => {
    /**
     * Если мы получили ошибку и она имеет тип fullPage, то мы должны кинуть исключение прямо в рендере
     * для того, чтобы компонент ErrorBoundary отловил ее
     */
    if (
      error &&
      'errorDisplayType' in error &&
      error?.errorDisplayType === 'fullPage'
    ) {
      throw error;
    }
  }, [error]);

  const setLanguage = useCallback((language: string) => {
    if (!LANG_LIST.includes(language)) return;
    changeLanguage(language);
  }, []);

  const ctx = useMemo(() => {
    const api = new Api(env.baseURL, setError);
    return { ...env, api, setLanguage, language };
  }, [env, setError, language]);

  return (
    <PaymentPageContext.Provider value={ctx}>
      {children}
    </PaymentPageContext.Provider>
  );
};

export const useApi = () => useContext(PaymentPageContext).api;

export const useDoRedirectFlag = () =>
  useContext(PaymentPageContext).doRedirect;

export const usePaymentPageLanguage = () => {
  const { setLanguage, language } = useContext(PaymentPageContext);

  return { setLanguage, language };
};
