import { translationEn } from './en';

// Переводить нужно только необходимые локализации
export const translationRu: typeof translationEn = {
  ...translationEn,
  common: {
    ...translationEn.common,
    scanQrInYouBankAppText: 'Откройте приложение банка и отсканируйте QR-код',
    clickProceedToPaymentButtonText:
      "<ghost>Или с мобильного устройства выберите <darker>'Перейти\xa0к\xa0оплате'</darker></ghost>",
    timeToPaymentText: '<darker>Время на оплату {{time}}</darker>',
    orderNumber: 'Номер заказа',
    orderDescription: 'Описание',
    amount: 'Сумма',
    paymentProcessingTitle: 'Обработка платежа',
    paymentProcessingText: 'Платёж находится в процессе обработки.',
    pleaseWaitText: 'Пожалуйста, подождите',
    paymentDeclined: 'Оплата отклонена',
    wilBeRedirected:
      'Вы будете перенаправлены на страницу магазина через <darker>{{timer}} секунд</darker>',
    orderPaid: 'Заказ оплачен',
    timeToPaymentExpired: 'Время на оплату истекло',
    whiteWhileReturnToStore:
      'Пожалуйста, ожидайте возврата на страницу магазина',
    triesNumberIsExceed: 'Пожалуйста, ожидайте возврата на страницу магазина',
  },
  buttons: {
    ...translationEn.buttons,
    proceedToPayment: 'Перейти к оплате',
    details: 'Детали заказа',
    hide: 'Скрыть',
    backToMerchant: 'Вернуться в магазин',
    selectMethod: 'Попробовать ещё раз',
  },
};
